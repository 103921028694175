import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Layout from "../components/layouts/Layout.js"
import ContactForm from "../components/contactForm.js"
import Banner from "../components/Banner.js"
import * as styles from "../stylesheets/pages/free-consultation.module.scss"

const FreeConsultation = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicFreeConsulation {
        data {
          banner_image {
            alt
            url
          }
          description {
            richText
          }
          header {
            text
          }
          sub_header {
            text
          }
          title {
            text
          }
        }
      }
    }
  `).prismicFreeConsulation.data

  return (
    <Layout
      title="Request a Free IT Consultation - TechCollective"
      description="Get in touch with us today to set up a free consultation with one of our owner/technicians."
    >
      <Banner subHeader={data.sub_header.text} alt={data.banner_image.alt} />
      <div className={styles.container}>
        <h2 className={styles.title}>Request a Free Consultation</h2>
        <div className={styles.description}>
          <RichText
            render={data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <ContactForm type={"consultation"} showLocation />
      </div>
    </Layout>
  )
}

export default FreeConsultation
